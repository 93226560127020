import React, { useEffect, useState } from "react";
import axios from "axios";
import closeBlack from "../../../../assets/images/close-black.png";
import sendIcon from "../../../../assets/images/send.png";
import profileIcon from "../../../../assets/images/profile.png";
import moreIcon from "../../../../assets/images/more.png";
import arrowIcon from "../../../../assets/images/arrow.png";
import edit from "../../../../assets/images/edit.png";
import trash from "../../../../assets/images/trash.png";

function Index(props) {
    const [updateData, setUpdateData] = useState([]);
    const [check, setCheck] = useState(0);
    const [buttonShow, setButtonShow] = useState(false);
    const [newReply, setNewReply] = useState("");
    const [modalData, setModalData] = useState([]);
    const [newComment, setNewComment] = useState("");


    const updateCommentData = (data) => {
        setUpdateData(data);
        setNewComment(data.note);
        setCheck(1);
        setButtonShow(false);
    };

    const submitRep = (e, data, index) => {
        if (e.key === "Enter") {
            let repData = modalData[index].reply;
            let obj = {
                id: Math.random * 100,
                user_name: "vishal",
                note: newReply,
            };
            repData.push(obj);
            setModalData([]);
            setTimeout(function () {
                setModalData(modalData);
            }, 300);
        }
    };

    const handleReply = (data, index) => {
        let newData = modalData;
        newData[index].isReply = !newData[index].isReply;
        console.log(newData);
        setModalData([]);
        setTimeout(function () {
            setModalData(newData);
        }, 300);
    };

    const deleteComment = (data) => {
        axios.delete(`http://localhost:3004/claim/${data.id}`).then((response) => {
            console.log(response);
            getData();
            setButtonShow(false);
        });
    };

    const postComment = () => {
        if (check === 0) {
            if (newComment.length === 0) {
                return;
            } else {
                axios
                    .post("http://localhost:3004/claim", {
                        claim_id: props.claimId,
                        user_name: "xavior",
                        note: newComment,
                        reply: [],
                        isReply: false,
                    })
                    .then((response) => {
                        console.log(response);
                        getData();
                        setNewComment("");
                    });
            }
        } else if (check === 1) {
            if (newComment.length === 0) {
                return;
            } else {
                axios
                    .patch(`http://localhost:3004/claim/${updateData.id}`, {
                        note: newComment,
                    })
                    .then((response) => {
                        console.log(response);
                        setCheck(0);
                        getData();
                    });
            }
        }
    };

    const getData = () => {
        axios.get("http://localhost:3004/claim").then((response) => {
            let data = response.data.filter(
                (data) => data.claim_id === props.claimId
            );
            setModalData(data);
        });
    };

    useEffect(() => {
        console.log(modalData);
    }, [modalData]);

    useEffect(() => {
        getData();
    }, [props.claimId]);

    return (
        <div
            style={{ top: props.topHeight + "px",left:props.commentLeft + "%" }}
            className={
                props.isFilter == false ? "hide messagebox card" : "messagebox card"
            }>
            <div className="msg_title">
                <h5>Add Comment</h5>
                <a href="javascript:;" className="close">
                    <img
                        src={closeBlack}
                        onClick={() => {
                            setButtonShow(false);
                            props.handleComment();
                        }}
                        className="icon"
                        alt="info"
                    />
                </a>
            </div>
            {modalData.map((data, index) => {
                return (
                    <div key={data.id} className="msg_body">
                        <div className="comment_content" key={data.id}>
                            <div className="comment_content_header">
                                <div>
                                    <div className="profiledtl">
                                        <div className="profileimg">
                                            <img src={profileIcon} alt="profile" />
                                        </div>
                                        <div>
                                            <strong className="nm">{data.user_name}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-muted">
                                    <span>
                                        <span>12.01.2023</span> <span>16:23</span>
                                    </span>
                                    <div className="more">
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setButtonShow(!buttonShow);
                                            }}>
                                            <img src={moreIcon} alt="more" />
                                        </a>
                                        {buttonShow ? (
                                            <div className="more_cnt">
                                                <div>
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => {
                                                            updateCommentData(data);
                                                        }}>
                                                        <img src={edit} alt="edit" />
                                                        <span> Edit </span>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        onClick={() => {
                                                            deleteComment(data);
                                                        }}>
                                                        <img src={trash} alt="trash" />
                                                        <span> Delete </span>
                                                    </a>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <p>{data.note}</p>
                            {data.reply.map((message, ind) => {
                                return (
                                    <div key={ind} className="reply__msg">
                                        <ul>
                                            <li>
                                                <div>
                                                    <div className="profiledtl">
                                                        <div className="profileimg">
                                                            <img src={profileIcon} alt="profile" />
                                                        </div>
                                                        <div>
                                                            <strong className="nm">
                                                                {message.user_name}
                                                            </strong>
                                                            <div>
                                                                <span className="text-muted">
                                                                    <span>21.10.2022</span>{" "}
                                                                    <span>23:00 / 22:23</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="more">
                                                            <a href="#">
                                                                <img src={moreIcon} alt="more" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="cnt">
                                                        <strong>Not:</strong>
                                                        <p>{message.note}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            })}
                            <div className="reply hr">
                                {!data.isReply ? (
                                    <>
                                        <a
                                            href="#"
                                            className="reply__action"
                                            onClick={() => {
                                                handleReply(data, index);
                                            }}>
                                            <img src={arrowIcon} alt="arrow" className="icon" />
                      Reply
                                        </a>
                                    </>
                                ) : (
                                    <div className="reply__area">
                                        <span>
                                            <input
                                                type="text"
                                                placeholder="Reply"
                                                className="inputreply"
                                                onChange={(e) => setNewReply(e.target.value)}
                                                onKeyDown={(e) => {
                                                    submitRep(e, data, index);
                                                }}
                                            />
                                            <button
                                                onClick={() => {
                                                    handleReply(data, index);
                                                }}>
                        X
                                            </button>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="msg_footer">
                <div className="form">
                    <input
                        type="text"
                        value={newComment}
                        placeholder="Comment"
                        onChange={(e) => {
                            setNewComment(e.target.value);
                        }}
                        name=""
                    />
                    <button
                        onClick={() => {
                            postComment();
                        }}>
                        <img src={sendIcon} className="" alt="send" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Index;
