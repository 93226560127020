import React, { useState, useEffect} from "react";
import reasons  from "../../../../assets/images/reasons.png";
import demand  from "../../../../assets/images/demand.png";
// import addIcon  from "../../../../assets/images/add.png";
// import questionIcon 	from "../../../../assets/images/question.png";


function Index(props) {

    const [cliamCount, setCliamCount] = useState(0);
    const [objectionCount, setObjectionCount] = useState(0);
   
    const [cliamDoc, setCliamDoc] = useState(0);
    const [objectionDoc, setObjectionDoc] = useState(0);

    useEffect(() => {
        
        let cliams=0;
        let objections=0;
        let evedenceDoc=0;
        let objectionDoc=0;
       
        props.orgData.map((item)=>{
            item.children.map((subitem)=>{
                cliams++;
                
                objections+=subitem.children.filter(subi=>subi.type==1).length;

                evedenceDoc+=subitem.children.filter(subi=>subi.type==2 && subi.url!="").length;

                subitem.children.filter(subi=>subi.type==1).map((subsubitem)=>{
                    subsubitem.children.map((subsubsubitem)=>{
                        if(subsubsubitem.url!="")
                        {
                            objectionDoc++;
                        }
                    });
                });
                
            });
        });

        setCliamCount(cliams);  
        setObjectionCount(objections);
        setCliamDoc(evedenceDoc);  
        setObjectionDoc(objectionDoc); 
        
        
    },[props.orgData]);

    return (
        <div className={props.isKpifeature==false ? "hide kpifeature_section":"kpifeature_section"} >
        	<ul>
        		<li className="reasons">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
        						<span>Decision</span>
        						<div className="strong">{cliamCount}</div>
        						<span className="txt">Delil {cliamDoc}</span>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<span>
        								<img src={reasons} alt="" className="icon" />
        							</span>
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		<li className="demand">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
        						<span>Opposing Decision </span>
        						<div className="strong">{objectionCount}</div>
        						<span className="txt">Delil {objectionDoc}</span>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<span>
        								<img src={demand} alt="" className="icon" />
        							</span>
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		
        	</ul>
        </div>
    );
}

export default Index;
