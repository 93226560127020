import React from "react";
import infoBlack from "../../../../assets/images/info-black.png";
// import questionWhite from "../../../../assets/images/question_white.png";
// import addWhite from "../../../../assets/images/add_white.png";
// import checkWhite from "../../../../assets/images/add_white.png";
import closeWhite from "../../../../assets/images/close_white.png";
// import minusWhite from "../../../../assets/images/add_white.png";
import hamorwhite from "../../../../assets/images/hamor-white.png";
import questionWhite from "../../../../assets/images/start.png";
import commentblank from "../../../../assets/images/comment-blank.png";


function Index() {
    return (
        <div className="element_info">
	        <a href="#"> 
	          <img src={infoBlack} className="icon" alt="info" />
	          <span>Bilgi</span>
	        </a>
	        <ul> 
	        	<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
	           <li className="contention">
	            	<a href="#">
		              	<img src={questionWhite} className="icon" alt="info" />
		              	<strong>Document Name</strong>
		              	<span>Tartışma Konusu</span>
	            	</a>
	          	</li>
          	  	<li className="reasons"> 
		            <a href="#">
			            <img src={hamorwhite} className="icon" alt="info" />
			            <strong>Decision</strong>
			            <span>Karar</span>
		            </a>
	          	</li>
		        <li className="objection">
		            <a href="#">
		               <img src={hamorwhite} className="icon" alt="info" />
		               <strong>Opposite Decision</strong>
		               <span>delil</span>
		            </a>
		        </li>
		        <li className="evidence">
		            <a href="#">
		               <img src={commentblank} className="icon" alt="info" />
		               <strong>Jurisprudence</strong>
		               <span>İtiraz</span>
		            </a>
		        </li>
		        <li className="counter_evidence">
		            <a href="#">
		              	<img src={closeWhite} className="icon" alt="info" />
		                <strong>Negative vote</strong>
		                <span>Karşı iddia</span>
		            </a>
		        </li>
	        </ul>
	    </div>
    );
}

export default Index;
