import React, {useState, useEffect} from "react";
import close from "../../../../assets/images/close-black.png";
//import star from "../../../../assets/images/star.png";
import filter from "../../../../assets/images/filter-black.png";
import Multiselect from "multiselect-react-dropdown";


function Index(props) {
    const [optionsOrg, setOptionsOrg] = useState([{name: "Davoci", id: " Davoci"}]);
    const [sidesOption, setSidesOption] = useState([{name: "Davoci", id: " Davoci"}]);
    const [selectedOrg, setSelectedOrg] = useState([]);
    const [selectedSide, setSelectedSide] = useState([]);
    const [countAllFilter, setAllFilter] = useState(0);
    const [clearFilterFlag, setClearFilterFlag] = useState(0);

    const multiselectOrgRef = React.createRef();
    const multiselectSideRef = React.createRef();

 
    const makeData =() => {

        let title=[];
       
        props.data.map((item)=>{
            item.children.filter((it)=>it.type!=4 && it.type!=5).map((subitem)=>{
                if(props.selectedArg.length>0 && props.selectedArg.filter((res)=>res.id==1).length==1 ){
                    title.push({name:subitem.title,id:subitem.title});
                }

                if(props.selectedArg.length>0 && props.selectedArg.filter((res)=>res.id==2).length==1 ){

                    subitem.children.filter(subi=>subi.type==1).map((subsubitem)=>{
                        title.push({name:subsubitem.title,id:subsubitem.title});
                    });
                }
               
            });
        });
        setOptionsOrg(title);
        setSidesOption([{name: "Davoci", id: " Davoci"}]);

    };


    const handleOrg=(e)=>{
        setSelectedOrg(e);
    };


    const handleSide=(e)=>{
        setSelectedSide(e);
    };


    const handleCloseFilter =() => {
        props.handleCloseFilter();
    };

    const handleClearFilter =() => {

        setSelectedOrg([]);
        setSelectedSide([]);
        multiselectSideRef.current.resetSelectedValues();
        multiselectOrgRef.current.resetSelectedValues();
        setClearFilterFlag(Math.random());

       
    };

    

    const handleApplyFilter =() => {
        let dataF={title:selectedOrg,side:selectedSide};
        props.handleApplyFilter(dataF);
    };

    const handelCountFilter =() => {
        let countF=0;
        countF=selectedOrg.length+selectedSide.length;
        setAllFilter(countF);
    };


    useEffect(() => {
        if(clearFilterFlag!=0){
            handleApplyFilter();
        }
    },[clearFilterFlag]);


    useEffect(() => {
        handelCountFilter();
    },[selectedOrg,selectedSide]);

    useEffect(() => {
        makeData();
    },[props.refresh]);


    return (
        <div  className={props.isFilter==false ? "hide filter_section":"filter_section"} >
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0"><img src={filter} alt="check" /> <span>Filters</span>{countAllFilter > 0 ? <span className="filternum">{countAllFilter}</span> :""}</h5>
                        <div>
                            <a href="javascript:;" onClick={()=>handleClearFilter()} ><span>Clear Filters</span> </a> 
                            <img src={close} onClick={()=>handleCloseFilter()} alt="check" style={{"cursor":"pointer", marginLeft:"5px"}} />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="row">
                           
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Taraf</label>
                                    <Multiselect
                                        options={sidesOption}
                                        displayValue="name"
                                        onSelect={(e)=>handleSide(e)}
                                        onRemove={(e)=>handleSide(e)}
                                        placeholder={selectedSide.length==0?"All Side":""}
                                        selectedValues={selectedSide}
                                        ref={multiselectSideRef}
                                    /> 

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Başlık</label>

                                    <Multiselect
                                        options={optionsOrg}
                                        displayValue="name"
                                        onSelect={(e)=>handleOrg(e)}
                                        onRemove={(e)=>handleOrg(e)}
                                        placeholder={selectedOrg.length==0?"All Title":""}
                                        selectedValues={selectedOrg}
                                        ref={multiselectOrgRef}
                                    />

                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
                <div className="card-footer">
                    {/*<div className="d-flex align-items-center justify-content-between">
                        <div>
                            <a href="#"><img src={star} alt="check" /> <span>Save Filters</span></a>
                        </div>*/}
                    <div className="text-right">
                        <div>
                            <a href="javascript:;" onClick={()=>handleApplyFilter()} className="btn btn-sm">Apply</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
