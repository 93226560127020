import React, { useState, useEffect, useRef, createRef } from "react";
import startIcon from "../../../../assets/images/start.png";
import infoIcon from "../../../../assets/images/info.png";
import commentIcon from "../../../../assets/images/comment.png";
import linkIcon from "../../../../assets/images/link.png";
import linkIconwhite from "../../../../assets/images/link-white.png";
// import addIcon from "../../../../assets/images/add.png";
// import closeIcon from "../../../../assets/images/close.png";
import questionIcon from "../../../../assets/images/question.png";
import externalLinkIcon from "../../../../assets/images/external-link.png";
import demandIcon from "../../../../assets/images/demand.png";
import reasonsIcon from "../../../../assets/images/reasons.png";
import ProgreshChart from "./ProgreshChart";
import jsPDF from "jspdf";
import Comment from "./Comment";
import Highlighter from "react-highlight-words";

function Index(props) {

    const [argumentData, setArgumentData] = useState([]);
    const [evidenceHeights, setEvidenceHeights] = useState([]);
    const [evidenceIndex, setEvidenceIndex] = useState([]);
    const [claimHeights, setClaimHeights] = useState([]);
    const [objectionIndex, setObjectionIndex] = useState([]);
    const [objectionHeights, setObjectionHeights] = useState([]);
    //const [caseHeights, setCaseHeights] = useState([]);
    const [test, setTest] = useState("");
    const reportTemplateRef = useRef(null);

    const [claimIndex, setClaimIndex] = useState(0);
    const [requestIndex, setRequestIndex] = useState(0);
    const [isComment, setIsComment] = useState(false);
    const [topHeight, setTopHeight] = useState(0);
    const [commentLeft, setCommentLeft] = useState(0);

    const [claimId, setClaimId] = useState(null);
    const finalDecisionRef = useRef(null);
    const opposingVotesRef = useRef(null);

    



    let dynamicData = [];
    for (var i = 0; i < 500; i++) {
        dynamicData.push({ test: 1 });
    }

    const evidenceRef = useRef(dynamicData.map(() => createRef()));
    const claimRef = useRef(dynamicData.map(() => createRef()));
    const objectionRef = useRef(dynamicData.map(() => createRef()));
    const caseRef = useRef(dynamicData.map(() => createRef()));

    const makeIndex = (data, from) => {

        let incIndex = 0;
        let rightIndex = 0;
        let incClaimIndex = 0;
        let incRequestIndex = 0;
        data.map((item) => {
            item.children.map((subitem) => {
               
                subitem.isClaim = 1;
                

                subitem.visible = false;
                subitem.isObjection = false;


                if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 1).length > 0 && subitem.isClaim == 1) {
                    subitem.visible = true;
                }
                else if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 2).length > 0 && subitem.isClaim == 0) {
                    subitem.visible = true;
                }
                else {
                    subitem.visible = false;
                }


                if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 3).length > 0) {
                    subitem.isObjection = true;
                }



                subitem.random = Math.random();
                if (from == "props" && props.filter.filterEvidence == false) {
                    subitem.isOpen = 0;
                }
                else if (from == "props" && props.filter.filterEvidence == true) {
                    subitem.isOpen = 1;
                }


                if (subitem.isOpen != undefined && subitem.isOpen == 1) {
                    subitem.children.filter(subi => subi.type == 2).map((subsubitem) => {

                        if (props.selectedArg.filter((res) => res.id == 1).length > 0 && props.selectedArg.filter((res) => res.id == 2).length > 0) {
                            subsubitem.index = incIndex;
                        }
                        else if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 1).length > 0 && subitem.isClaim == 1) {
                            subsubitem.index = incClaimIndex;
                            incClaimIndex++;
                        }
                        else if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 2).length > 0 && subitem.isClaim == 0) {
                            subsubitem.index = incRequestIndex;
                            incRequestIndex++;
                        }
                        incIndex++;


                    });
                }

                subitem.children.filter(subi => subi.type == 1).map((subsubitem) => {
                    subsubitem.index = rightIndex;

                    if (from == "props" && props.filter.filterEvidence == false) {
                        subsubitem.isOpen = 0;
                    }
                    else if (from == "props" && props.filter.filterEvidence == true) {
                        subsubitem.isOpen = 1;
                    }
                    rightIndex++;
                    if (subsubitem.isOpen != undefined && subsubitem.isOpen == 1) {
                        subsubitem.children.map((subsubsubitem) => {
                            subsubsubitem.index = rightIndex;
                            rightIndex++;
                        });
                    }

                });
            });
        });



        ///filter 

        data.map((item) => {
            item.children.map((subitem) => {
                if (props.filterAttribute.title.length == 0) {
                    subitem.disabled = false;
                    subitem.objDisabled = false;

                }
                else {
                    subitem.disabled = true;
                    subitem.objDisabled = true;
                }
                let cliF = props.filterAttribute.title.filter((ist) => ist.name == subitem.title);
                if (cliF.length) {
                    subitem.disabled = false;
                }

                //Obj filter
                

                let flagObjDis = 1;
                subitem.children.filter(subi => subi.type == 1).map((subsubitem) => {
                    if (props.filterAttribute.title.length == 0 ) {
                        subsubitem.disabled = false;
                    }
                    else {
                        subsubitem.disabled = true;
                    }
                    let cliO = props.filterAttribute.title.filter((ist) => ist.name == subsubitem.title);
                    if (cliO.length) {
                        subsubitem.disabled = false;
                        flagObjDis = 0;
                    }

                });

                if (flagObjDis == 0) {
                    subitem.objDisabled = false;
                }



            });
        });


        // highlight
        let highlight = 0;

        let search = props.searchFilter;
        search = search.toLowerCase();
        let flagHighlightClaim = 0;
        let flagHighlightObjection = 0;

        data.map((item) => {
            item.children.map((subitem) => {

                flagHighlightClaim = 0;
                flagHighlightObjection = 0;



                flagHighlightClaim += subitem.title.toLowerCase().split(search).length - 1;

                flagHighlightClaim += subitem.description.toLowerCase().split(search).length - 1;

                if (subitem.isOpen == 1) {
                    subitem.children.filter(subi => subi.type == 2).map((subsubitem) => {
                        flagHighlightClaim += subsubitem.title.toLowerCase().split(search).length - 1;

                        flagHighlightClaim += subsubitem.description.toLowerCase().split(search).length - 1;

                    });

                }

                subitem.children.filter(subi => subi.type == 1).map((subsubitem) => {
                    if (subsubitem.disabled == false) {
                        flagHighlightObjection += subsubitem.title.toLowerCase().split(search).length - 1;
                        flagHighlightObjection += subsubitem.description.toLowerCase().split(search).length - 1;
                    }

                    if (subsubitem.isOpen == 1 && subsubitem.disabled == false) {

                        subsubitem.children.map((subsubsubitem) => {
                            flagHighlightObjection += subsubsubitem.title.toLowerCase().split(search).length - 1;
                            flagHighlightObjection += subsubsubitem.description.toLowerCase().split(search).length - 1;

                        });
                    }

                });
                if (subitem.visible == false || subitem.disabled == true || search == "") {
                    flagHighlightClaim = 0;
                }
                if (subitem.isObjection == false || subitem.objDisabled == true || search == "") {
                    flagHighlightObjection = 0;
                }
                highlight += flagHighlightClaim;
                highlight += flagHighlightObjection;

                subitem.highlightClaim = flagHighlightClaim;
                subitem.highlightObjection = flagHighlightObjection;

            });


        });

        props.setHighlightCount(highlight);

        setClaimIndex(incClaimIndex);
        setRequestIndex(incRequestIndex);
        setEvidenceIndex(incIndex);
        setObjectionIndex(rightIndex);
        setArgumentData(data);

        setTest(Math.random());



    };


    const segmentCalculate = (item, index) => {
        let LeftCaculate = leftHeightCalculate(item, index);

        if (props.selectedArg.length > 0 && (props.selectedArg.filter((res) => res.id == 1).length == 0 && props.selectedArg.filter((res) => res.id == 2).length == 0)) {

            LeftCaculate = 0;
        }
        let rightCaculate = rightHeightCalculate(item);
        console.log("rightCaculate",rightCaculate); 
        if (rightCaculate >= LeftCaculate) {
            return rightCaculate;
        }
        else {
            return LeftCaculate;

        }
    };

    const rightHeightCalculate = (item) => {
        let rightTotal = 0; let subTotal = 0;
        item.children.filter(su => su.type == 1).map((subSubItem, index) => {
            subTotal = 0;
            subSubItem.children.map((subSubSubItem) => {
                console.log(objectionHeights[subSubSubItem.index],"ok",subSubItem.index);
                subTotal += objectionHeights[subSubSubItem.index] + 50;
            });
            console.log("ssssss", subTotal, subSubItem.index);

            if (subTotal >= objectionHeights[subSubItem.index] && subSubItem.isOpen == 1) {
                rightTotal += subTotal;
            }
            else {
                if (item.children.filter(su => su.type == 1).length - 1 == index && item.children.filter(su => su.type == 1).length == 1) {
                    rightTotal += objectionHeights[subSubItem.index] + 60;
                }
                else {
                    rightTotal += objectionHeights[subSubItem.index] + 50;
                }

            }

        });

        return rightTotal;


    };

    const leftHeightCalculate = (item, index) => {
        let leftTotal = 0;
        if (item.visible == true) {
            item.children.filter(su => su.type == 2).map((subSubItem) => {

                leftTotal += evidenceHeights[subSubItem.index] + 50;

            });


        }


        console.log(leftTotal, "test", index);

        if (leftTotal >= claimHeights[index] && item.isOpen == 1) {
            return leftTotal;
        }
        else {
            return claimHeights[index] + 60;
        }
    };

    const leftLastNodeCaclculete = (item) => {
        let leftTotal = 0;

        item.children.filter(su => su.type == 2).map((subSubItem) => {

            leftTotal = evidenceHeights[subSubItem.index];

        });

        return leftTotal + "px";

    };

    const rightSubObjectionHeightCal = (rightNodeLnegth, item, index) => {
        let rightTotal = 0;
        if (rightNodeLnegth == 1) {
            return objectionHeights[index] + 2;
        }
        item.children.map((subSubItem) => {
            rightTotal += objectionHeights[subSubItem.index];
        });

        if (rightTotal >= objectionHeights[index]) {
            //return rightTotal+objectionHeights[index];
            return rightTotal + 50;
        }
        else {
            return objectionHeights[index] + 50;
        }
    };

    const rightLastNodeCaclculete = (item) => {
        let rightTotal = 0;

        item.children.filter(su => su.type == 1).map((subSubItem) => {
            rightTotal = 0;
            subSubItem.children.map((subsubSubItem) => {
                rightTotal += objectionHeights[subsubSubItem.index] + 50;
            });
            if (rightTotal == 0) {
                rightTotal = objectionHeights[subSubItem.index];

            }
            else {
                rightTotal;
                //rightTotal+=objectionHeights[subSubItem.index]+20;
            }

        });

        return rightTotal + "px";

    };

    const rightSubLastNodeCaclculete = (item) => {
        let rightTotal = 0;
        item.children.map((subSubItem) => {

            rightTotal = objectionHeights[subSubItem.index];

        });

        return rightTotal + "px";

    };

    const toggleNode = (item, flag, ind) => {
        console.log(ind);
        item.isOpen = flag;
        makeIndex(argumentData, "state");

        setTimeout(function () {
            jumpToNode(ind);
        }, 200);



    };

    const jumpToNode = (ind) => {
        scrollToNode(ind);
    };

    const scrollToNode = (ind) => {


        caseRef.current.map((ref, index) => {
            if (argumentData.length) {
                if (argumentData[0].children.filter((it)=>it.type!=4 && it.type!=5).length > index && index == ind) {

                    ref.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center", inline: "nearest"
                    });
                }
            }

        });


    };


    const handleGeneratePdf = () => {

        const doc = new jsPDF({
            orientation: "p",
            unit: "pt",
            format: [1366, props.totalHeight.current.offsetTop + 100],

        });

        // Adding the fonts
        doc.setFont("Inter-Regular", "normal");

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save("document");
                props.filterExportCallBack();
            }
        });

    };

    const handleCommentOpen = (clf, subItem) => {
        let topH = clf.current.parentNode.offsetTop + clf.current.offsetTop;
        setTopHeight(topH);
        setIsComment(true);
        setCommentLeft(48);
        setClaimId(subItem.isClaim);
    };


    const handleObjectionCommentOpen = (event, clf, subItem) => {
        console.log(subItem, clf);
        console.log(event.pageY);
        let topH = event.pageY - 20;
        setTopHeight(topH);
        setCommentLeft(72);
        setIsComment(true);
        setClaimId(2);
    };

    const handleDecisionCommentOpen = (clf, subItem) => {
        console.log(subItem);
        let topH = clf.current.parentNode.offsetTop + 90;
        setTopHeight(topH);
        setIsComment(true);
        setCommentLeft(70);
        setClaimId(66);
    };

    

    const handleComment = () => {
        setIsComment(false);
    };

    const randerHighlighter = (text) => {
        //let searchText= "and o the";
        //const searchWords = searchText.split(/\s/).filter(word => word);
        return (
            <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[props.searchFilter]}
                textToHighlight={text}
                activeIndex="-1"
                caseSensitive={false}
            />
        );
    };



    useEffect(() => {
        var hights = [];
        var cHeight = [];
        var oHeight = [];
        //var casHeight=[];
        let evIndex = evidenceIndex;
        if (props.selectedArg.filter((res) => res.id == 1).length > 0 && props.selectedArg.filter((res) => res.id == 2).length > 0) {
            evIndex = evidenceIndex;
        }
        else if (props.selectedArg.filter((res) => res.id == 1).length > 0) {
            evIndex = claimIndex;
        }
        else if (props.selectedArg.filter((res) => res.id == 2).length > 0) {
            evIndex = requestIndex;
        }
        else {
            evIndex = 0;
        }


        ///evIndex=evidenceIndex;


        if (argumentData.length) {

            evidenceRef.current.map((ref, index) => {
                if (evIndex > index) {
                    hights.push(ref.current.clientHeight);
                }
            });

            setEvidenceHeights(hights);

            if (props.selectedArg.length > 0 && (props.selectedArg.filter((res) => res.id == 1).length > 0)) {


                claimRef.current.map((ref, index) => {
                    if (argumentData.length) {
                        if (argumentData[0].children.filter((it)=>it.type!=4 && it.type!=5).length > index) {
                            cHeight.push(ref.current.clientHeight);
                        }
                    }
                });
            }

            setClaimHeights(cHeight);


            if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 3).length > 0) {
                objectionRef.current.map((ref, index) => {
                    if (objectionIndex > index) {
                        oHeight.push(ref.current.clientHeight);
                    }
                });
            }

            setObjectionHeights(oHeight);
        }

    }, [test]);

    useEffect(() => {
        makeIndex(props.data, "props");

    }, [props.refresh]);

    useEffect(() => {
        if (props.filterExport != "") {
            handleGeneratePdf();
        }

    }, [props.filterExport]);



    return (
        <>
            <div>
                <Comment handleComment={handleComment} commentLeft={commentLeft} claimId={claimId} topHeight={topHeight} isFilter={isComment} />
                <div id="report" ref={reportTemplateRef} className="argumentmap-zoom argumentmap">




                    {/* Argumentmap chart */}
                    {argumentData.length > 0 && argumentData.map((item, index) =>
                        <React.Fragment key={index} >
                            <div className="start-points card text-center">
                                <div className="link"><a rel="noopener noreferrer" href="#" target="_blank"><img src={linkIconwhite} className="icon" alt="link" /></a></div>
                                <img src={startIcon} className="icon m-auto" alt="question" />
                                <p><strong>{item.name}</strong></p>
                                {/*<div className="search_section">
                                    <div className="search_field">
                                        <input type="text" value={searchFilter} onChange={(e)=>handleSearch(e)} className="form-control search form-control-sm" placeholder="Content Search: Muhakemat" />
                                        {highlightCount>0 && <span>{highlightCount}</span>}
                                    </div>
                                </div>*/}
                                <div className="text-center">
                                    <a href="#" target="_blank" rel="noreferrer" className="btn">Belge Tipi</a>
                                </div>
                            </div>
                            {item.children.filter((it)=>it.type!=4 && it.type!=5).length && item.children.filter((it)=>it.type!=4 && it.type!=5).map((subItem, subIndex) =>
                                <React.Fragment key={"subIndex" + subIndex}>
                                    <div className={`case_argument ${subItem.children.length > 0 && subItem.isObjection == true && subItem.children.filter(su => su.type == 1).length > 1 && " margin40"} `} style={{ height: segmentCalculate(subItem, subIndex) }} key={subIndex}>
                                        {/* left section */}
                                        {subItem.visible == true &&
                                            <div data-test={subItem.visible} ref={caseRef.current[subIndex]} className={`reasons ${subItem.disabled == true && "section-disable"} ${subItem.isClaim == 0 && "demand"}`} style={{ height: "auto" }}>
                                                <div className={subItem.isClaim == 0 ? "card skyblue" : "card green"} ref={claimRef.current[subIndex]}>
                                                    {subItem.children.filter(su => su.type == 2).length > 0 && subItem.isOpen == 1 &&
                                                        <div className="sublist_openclose open" onClick={() => toggleNode(subItem, 0, subIndex)}></div>
                                                    }
                                                    {subItem.children.filter(su => su.type == 2).length > 0 && subItem.isOpen == 0 &&
                                                        <div className="sublist_openclose" onClick={() => toggleNode(subItem, 1, subIndex)}></div>
                                                    }
                                                    <div className="p-relative">
                                                        {subItem.isClaim == 1 ?
                                                            <a href="javascript:;" className="action_icon add"><img src={reasonsIcon} alt="add" /></a>
                                                            :
                                                            <a href="javascript:;" className="action_icon add"><img src={questionIcon} alt="add" /></a>
                                                        }
                                                        <a href="javascript:;" className="comment_icon">
                                                            <img src={commentIcon} onClick={() => handleCommentOpen(caseRef.current[subIndex], subItem)} alt="chat" />
                                                            <div className="messagebox card" style={{ display: "none" }}>
                                                                <div className="msg_title">
                                                                    <img src={infoIcon} className="icon" alt="info" />
                                                                    <h5>Consectetur</h5>
                                                                </div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            </div>
                                                        </a>
                                                        <div>
                                                            <h6>
                                                                {randerHighlighter(subItem.title)}
                                                                {subItem.document.length > 0 &&
                                                                    <a href={subItem.document[0].link} target="_blank" rel="noreferrer" className="ml-05"  ><img width="12" src={externalLinkIcon} className="sup" /></a>
                                                                }
                                                            </h6>
                                                            <p>{randerHighlighter(subItem.description)}</p>
                                                            {
                                                                subItem.sides.length > 0 &&
                                                                <div className="">
                                                                    <a href="#" className="btn disabled"> <span>{subItem.sides[0]}</span></a>
                                                                    {subItem.document.length > 0 &&
                                                                        <a href={subItem.document[0].link} target="_blank" rel="noreferrer" className="btn">{subItem.document[0].type}</a>
                                                                    }
                                                                </div>
                                                            }

                                                        </div>
                                                        {subItem.children.filter(su => su.type == 2).length > 0 && subItem.isOpen == 1 &&
                                                            <React.Fragment  >
                                                                <div className={subItem.children.filter(su => su.type == 2).length == 1 ? "sublist open single" : "sublist open"}>
                                                                    {subItem.children.filter(su => su.type == 2).length == 1 ?
                                                                        <div className="connector" style={{ height: "calc(100% - 50% - 17px)" }}></div>
                                                                        :
                                                                        <div className="connector" style={{ height: "calc(100% -  " + leftLastNodeCaclculete(subItem) + ")" }}></div>
                                                                    }
                                                                    <ul>
                                                                        {subItem.children.length > 0 && subItem.children.filter(su => su.type == 2).map((subSubItem, subSubIndex) =>
                                                                            <li key={subSubIndex} ref={evidenceRef.current[subSubItem.index]} style={{ height: evidenceHeights[subSubItem.index] }} >
                                                                                {subSubItem.url != "" && <div className="link"><a rel="noopener noreferrer" href={subSubItem.url} target="_blank"><img src={linkIcon} className="icon" alt="link" /></a></div>}
                                                                                <div className="argument_txt" >
                                                                                    <div className="heading"><strong className="number">{subSubIndex + 1}</strong><strong className="nm">Evidence / </strong> <strong>{randerHighlighter(subSubItem.title)}</strong></div>
                                                                                    <p>{randerHighlighter(subSubItem.description)}</p>
                                                                                </div>
                                                                            </li>
                                                                        )}

                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        {subItem.visible == false &&
                                            <div className="reasons demand" ref={caseRef.current[subIndex]} style={{ height: "auto" }}> </div>
                                        }

                                        {/* right section */}
                                        {subItem.children.length > 0 && subItem.isObjection == true && subItem.children.filter(su => su.type == 1).length &&
                                            <div className={subItem.children.filter(su => su.type == 1).length == 1 ? "objection" : "objection multiple"}>
                                                <div className="connector" style={{ height: "calc(100% - " + rightLastNodeCaclculete(subItem) + ")" }} ></div>
                                                {subItem.children.length && subItem.children.filter(su => su.type == 1).map((subSubItem, subSubIndex) =>
                                                    <div className={`objection_cnt first ${subSubItem.disabled == true && "section-disable"} `} key={subSubIndex} style={{ height: rightSubObjectionHeightCal(subItem.children.filter(su => su.type == 1).length, subSubItem, subSubIndex) }}>
                                                        <div className="card red" ref={objectionRef.current[subSubItem.index]}>

                                                            {subSubItem.children.length > 0 && subSubItem.isOpen == 1 &&
                                                                <div className="sublist_openclose open" onClick={() => toggleNode(subSubItem, 0, subIndex)}></div>
                                                            }
                                                            {subSubItem.children.length > 0 && subSubItem.isOpen == 0 &&
                                                                <div className="sublist_openclose" onClick={() => toggleNode(subSubItem, 1, subIndex)}></div>
                                                            }
                                                            <div className="p-relative">
                                                                <div>
                                                                    <a href="javascript:;" className="action_icon close"><img src={demandIcon} alt="add" /></a>
                                                                    <a href="javascript:;" className="comment_icon" >
                                                                        <img src={commentIcon} alt="chat" onClick={(e) => handleObjectionCommentOpen(e, objectionRef.current[subSubItem.index], subSubItem)} />
                                                                        <div className="messagebox card" style={{ display: "none" }}>
                                                                            <div className="msg_title">
                                                                                <img src={infoIcon} className="icon" alt="info" />
                                                                                <h5>Consectetur</h5>
                                                                            </div>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                                        </div>
                                                                    </a>
                                                                    <h6>
                                                                        {randerHighlighter(subSubItem.title)}
                                                                        {subSubItem.document.length > 0 &&
                                                                            <a href={subSubItem.document[0].link} target="_blank" className="ml-05" rel="noreferrer" ><img width="12" src={externalLinkIcon} className="sup" /></a>
                                                                        }
                                                                    </h6>
                                                                    <p>{randerHighlighter(subSubItem.description)}</p>
                                                                    {
                                                                        subSubItem.sides.length > 0 &&
                                                                        <div className="">
                                                                            <a href="#" className="btn disabled"> <span>{subSubItem.sides[0]}</span></a>
                                                                            {subSubItem.document.length > 0 &&
                                                                                <a href={subSubItem.document[0].link} target="_blank" rel="noreferrer" className="btn">{subSubItem.document[0].type}</a>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>

                                                                {subSubItem.children.length > 0 && subSubItem.isOpen == 1 &&
                                                                    <div className={subSubItem.children.length == 1 ? "sublist open single" : "sublist open"}>
                                                                        {subSubItem.children.length == 1 ?
                                                                            <div className="connector" style={{ height: "calc(100% - 50% - 17px)" }} ></div>
                                                                            :
                                                                            <div className="connector" style={{ height: "calc(100% - " + rightSubLastNodeCaclculete(subItem) + ")" }} ></div>

                                                                        }
                                                                        <ul>
                                                                            {subSubItem.children.length && subSubItem.children.map((subSubSubItem, subSubSubIndex) =>
                                                                                <li key={subSubSubIndex} ref={objectionRef.current[subSubSubItem.index]} style={{ height: objectionHeights[subSubItem.index] }} >
                                                                                    {subSubSubItem.url != "" && <div className="link"><a rel="noopener noreferrer" href={subSubItem.url} target="_blank"><img src={linkIcon} className="icon" alt="link" /></a></div>}
                                                                                    <div className="argument_txt" >
                                                                                        <div className="heading"><strong className="number">{subSubSubIndex + 1}</strong> <strong className="nm">Counter-Claim / </strong> <strong>{randerHighlighter(subSubSubItem.title)}</strong></div>
                                                                                        <p>{randerHighlighter(subSubSubItem.description)}</p>
                                                                                    </div>
                                                                                </li>
                                                                            )}

                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                   
                                </React.Fragment>
                            )}

                            <div className="case_argument">
                                {/* Other section */}
                                {props.selectedArg.filter((res)=>res.id==4).length && item.children.filter(su => su.type == 4).length > 0 && item.children.filter(su => su.type == 4).map((subItem, index) =>
                                    <div className="decision" key={"Index_" + index} ref={finalDecisionRef}>
                                        <div className={subItem.type == 4 ? "final_decision decision__box" : "opposing_votes decision__box"}>
                                            <a href="javascript:;" className="comment_icon"><img src={commentIcon} className="comment icon"  onClick={() => handleDecisionCommentOpen(finalDecisionRef, subItem)} /></a>
                                            <div className="">
                                                <h6>
                                                    {randerHighlighter(subItem.title)}
                                                </h6>
                                                <p>{randerHighlighter(subItem.description)}</p>

                                                {
                                                    subItem.sides.length > 0 &&
                                                    <div className="">
                                                        <a href="#" className="btn disabled"> <span>{subItem.sides[0]}</span></a>
                                                       
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="case_argument">
                                {props.selectedArg.filter((res)=>res.id==5).length && item.children.filter(su => su.type == 5).length > 0 && item.children.filter(su => su.type == 5).map((subItem, index) =>
                                    <div className="decision" key={"Index_" + index} ref={opposingVotesRef}>
                                        <div className={subItem.type == 4 ? "final_decision decision__box" : "opposing_votes decision__box"}>
                                            <a href="javascript:;" className="comment_icon"><img src={commentIcon} className="comment icon" onClick={() => handleDecisionCommentOpen(opposingVotesRef, subItem)} /></a>
                                            <div className="">
                                                <h6>
                                                    {randerHighlighter(subItem.title)}
                                                </h6>
                                                <p>{randerHighlighter(subItem.description)}</p>

                                                {
                                                    subItem.sides.length > 0 &&
                                                    <div className="">
                                                        <a href="#" className="btn disabled"> <span>{subItem.sides[0]}</span></a>
                                                       
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </React.Fragment>
                    )}
                </div>
            </div>
            <ProgreshChart data={argumentData} handleJumpToNode={jumpToNode} />
        </>

    );
}

export default Index;
